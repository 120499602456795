export const EN_SCHEMA = {
    common: {
        cancel: "Cancel",
        add: "Add",
        me: "Me",
        name: "Name",
        horse: "Horse",
        rider: "Rider",
        presenter: "Presenter",
        loading_data: "Loading data..",
        back: "Back",
        registration: "Registration",
        close: "Close",
        save: "Save",
        search: "Search..",
        show_all: "Show all"
    },

    horse: {
        gender: {
            MALE: "Mannelijk",
            STALLION: "Hengst",
            MARE: "Merrie",
            GELDING: "Ruin",
            RIG: "Rig",
            FILLY_FOAL: "Merrieveulen < 1y",
            COLT_FOAL: "Colt/Hengst veulen < 1y",
            FILLY: "Merrie < 4y",
            COLT: "Hengst < 4y",
            FILLY_YEARLING: "Merrie jaarling 1-2y",
            COLT_YEARLING: "Hengst jaarling 1-2y"
        }
    },

    section: {
        GENERAL: "General",
        RANKING: "Rankings"
    },

    error: {
        general: "Something went wrong.. (code: {code})",
        404: {
            title: "Nothing found",
            subtitle: "We could not find the data you are requesting"
        }
    },

    status: {
        CANCELLED: "Cancelled"
    },

    discipline: {
        SHOW_JUMPING: "Show jumping",
        DRESSAGE: "Dressage",
        STYLE_RIDING: "Style riding",
        CROSS_COUNTRY: "Cross country",
        EVENTING: "Eventing",
        PRESENTATION: "Presentation"
    },

    tabs: {
        start: "start",
        championship: "championships",
        search: "search",
        cart: "cart",
        profile: "profile"
    },

    loader: {
        loading: "Retrieving data"
    },

    start: {
        header_tabs: {
            live: "Live",
            calendar: "Calendar",
            my_calendar: "My calendar",
            channels: "Channels"
        },
        calendar: {
            upcoming: "Upcoming",
            today: "Today",
            earlier: "Earlier",
            nextWeek: "Next week",
            lastWeek: "Last week",
            thisWeek: "This week",
            later: "Later",
            older: "Older"
        },
        my_calendar: {
            title: "My calendar",
            description: "Determine what your calendar looks like here",
            disciplines: "Disciplines",
            channels: "Channels",
            championships: "Championships",
            registration_only: "Show only competitions where registrations are open"
        },
        failed: {
            message: "The app could not be started, please try again later.",
            try_again: "Try again",
        },
    },

    competition: {
        empty_list: "No competitions available yet",
        header_tabs: {
            register: "Register",
            program: "Program",
            results: "Results"
        },
        schedule: {
            empty: "Schedule not yet available"
        },
        person_input: {
            name_placeholder: "Rider's name..",
        },
        organisation_input: {
            organisation: "Organisation",
            organisations: "Organisations",
            available_organisations: "Available organisations",
            organisation_placeholder: "Organisation name..",
            select: "Select organisation",
            none_available: "No organisations available",
        },
        horse_input: {
            name_placeholder: "Horse's name..",
            ueln: "UELN",
            ueln_placeholder: "Horse's UELN..",
            pony: "Pony",
            birthdate: "Birthdate",
            withersheight: "Withersheight",
            withersheight_placeholder: "Choose withersheight",
            studbook: "Studbook",
            studbook_placeholder: "Choose studbook",
            gender: "Gender",
            gender_placeholder: "Choose gender",
            father: "Father",
            father_placeholder: "Father's name..",
            mother: 'Mother',
            mother_placeholder: "Mother's name..",
            father_of_mother: "Father of mother",
            father_of_mother_placeholder: "Father of mother's name..",
        },
        register: {
            label: "Register",
            warning: "You have to be signed in to make a registration",
            no_registration_tests: "No tests available yet",
            out_of_competition: "Out of competition",
            for_sale: "Offer this animal as 'For Sale'",
            individual: {
                label: "Register",
                description: "Individual registration form",
            },
            horseOnly: {
                label: "Register",
                description: "Horse registration form",
            },
            person: {
                persons: "Persons",
                available_persons: "Available persons"
            },
            rider: {
                riders: "Riders",
                available_riders: "Available riders",
                select: "Select rider",
                add: "Add rider",
                not_in_list: "Rider not in list?",
                none_available: "No riders available"
            },
            horse: {
                horses: "Horses",
                available_horses: "Available horses",
                select: "Choose horse",
                add: "Add horse",
                not_in_list: "Horse not in list?",
                none_available: "No horses available",
                search: {
                    title: 'Search horses',
                    empty: 'Search a horse..',
                    search_results: "Found horses",
                    no_search_results: "No horses found with name:"
                },
                my_horses: "My horses",
                search_horses: "Search",
                new_horse: "New",
                my_horses_info: "Overview of your recently used horses.",
                new_horse_info: "Add a new horse if it is not yet known in Eqify."
            },
            validation: {
                modal: {
                    title: "Attention!",
                    description: "Some (possible) problems were found"
                },
                with_warnings: "With warnings"
            },
            competition: {
                label: "Competition",
            },
            test: {
                label: "Test",
            },
            price: "Price",
            proceed: "Proceed",
            registration_added: "Registration added to cart",
            person_cannot_be_registered: "Person cannot be registered.",
            free_combination: "Free combination",
            free_combination_description: "Fill in the fields below",
            date: {
                beforeOpen: "Registrations open from:",
                isLate: "Last registrations possible!",
                willCloseSoon: "Registration closing soon!",
                isClose: "Registrations closed!",
                isOpen: "Registrations open!"
            }
        },
        startlist: {
            participant: "Participant",
            points: "Points",
            time: 'Time',
            expected: "Expected",
            excluded: "Excluded",
            rank: "Rank",
            pause: "Pause",
            coursedesign:"Course design",
            watchlive:"Watch live",
            finishStatus: {
                FINISHED: "Finished",
                NOT_STARTED: "Not Started",
                ELIMINATED: "Eliminated",
                WITHDRAWN: "Withdrawn",
                RETIRED: "Retired",
                DISQUALIFIED: "Disqualified",
                ERROR: "",
                INCOMPLETE: ""
            },
            detail: {
                header_tabs: {
                    result: "Result",
                    pedigree: "Pedigree",
                    video: "Video"
                },
                video: {
                    empty: {
                        title: "No videos available",
                        subtitle: "No videos were found for this combination"
                    }
                }
            }
        },
        info: {
            general: "General",
            program: "Program",
            visitors: "Visitors",
            vip: "VIP",
            trucks: "Trucks",
            trailers: "Trailers"
        }
    },

    championship: {
        detail: {
            competitions: {
                empty: {
                    title: "No competition results",
                    description: "The above points may be from a selection."
                }
            },
            fullList: "Show full ranking"
        }
    },

    activePractitioner: "Now live",
    finishedPractitioner: "Last finished",
    expectedPractitioners: "Upcoming shortly",

    timekeeperState: {
        FINISHED: "Finished",
        COUNTDOWN: "Countdown",
        RUNNING: "Running",
        WAITING: "Waiting"
    },

    timekeeperRunState: {
        PAUSE: "Paused"
    },

    payment: {
        DEFERRED: "Payment via your federation",
        DIRECT: "Online payment",
        FREE: "Free",
        EXTERN: "Payment on site",
        pay_now: "Payable now",
        proceed: "Proceed",
        complete: "Order successfully processed!",

        amount: 'Amount',
        status: {
            name: "Status",
            SUCCEEDED: "Paid",
            OPEN: "Not paid",
            FAILED: "Payment Failed",
            UNKNOWN: "Not paid"
        }
    },

    cart: {
        title: "Cart",
        description: "Checkout registrations",
        added_to_cart: "Added to cart",
        pay: "Pay",
        continue_pay: "Continue payment",
        checkout: "Confirm",
        continue_checkout: "Continue confirm",
        error: {
            REGISTRATION_IS_OUTSIDE_THE_ALLOWED_DATES: "Registration expired for this test",
            PRICE_PROFILE_CONFIGURATION_ERROR: "There was a problem with this registration (PP-1)",
            PRICE_PROFILE_IS_INVALID: "There was a problem with this registration (PP-2)",
            COMBINATION_REGISTRATION_NOT_ALLOWED: "This combination is not allowed to register"
        }
    },

    auth: {
        general: {
            title: "Your account",
            subtitle: "Sign up to access the full Eqify app"
        },
        login_or_register: "Login or register",
        login: "Login",
        register: "Create account",
        new_user: "Don't have an account on Eqify yet?",
        already_logged_in: "User is already logged in",
        continue: "Continue",
        update_password: {
            title: "Update password",
            new_password: "New password",
            confirm_password: "Confirm password",
            confirm_password_doesnt_match: "Confirm password doesn't match",
        },
        delete_account: {
            title: "Delete account",
            info: "Are you sure you want to delete your account with email: <b>{email}</b>?",
            confirm_email: "Type your email to confirm",
            confirm_email_doesnt_match: "Confirm email doesn't match",
            alert: {
                header: "Caution!",
                message: "This action cannot be undone, are you sure?",
            },
        },
        logout: {
            label: "Logout",
            alert: {
                header: "Caution!",
                message: "You are about to logout, are you sure?",
            },
        },
        pedigree: {
            title: "Login required",
            subtitle: "You must be logged in to view the Hippomundo pedigree"
        }
    },

    profile: {
        my_registrations: {
            title: "My registrations",
            description: "Overview of your registrations",
            empty: {
                title: "No registrations",
                description: "All of your registrations will appear here."
            },
            not_found: {
                title: "No search results",
                description: "We could not find any matches with your search."
            }
        },
        my_orders: {
            title: "My orders",
            description: "Overview of your registrations",
            order: "Orders",
            pay_online: "Pay online",
            payment: {
                status: {
                    PAYED: "Paid",
                    NOT_PAYED: "Not Paid",
                }
            },
            empty: {
                title: "No orders",
                description: "All of your orders will appear here."
            },
        },
        my_bills: {
            title: "My bills",
            description: "Overview of your bills",
            order: "Bills",
            empty: {
                title: "No bills",
                description: "All of your bills will appear here."
            },
            status: {
                CANCELLED: "Cancelled",
                ORDERED: "Ordered",
                OPEN: "Open"
            },
            detail: {
                empty: {
                    title: "No items found"
                }
            },
            type: {
                PERSONAL_BILL: "Personal bill",
                COMPETITION_BILL: "Competition bill",
                ORGANISATION_BILL: "Organisation bill",
                MEMBER_BILL: "Member bill"
            }
        },
        my_accounts: {
            title: "My accounts",
            account: "Account",
            description: "Overview of your accounts",
            empty: {
                title: "No accounts",
                description: "All of your accounts will appear here."
            },
            default: 'Default',
            default_action: "This is my default account",
            create: {
                label: "Create account",
                description: "Fill in the form to create an account."
            },
            select: {
                label: "Select account",
                description: "Select an account from the list below"
            },
            form: {
                name: "Name",
                type: {
                    label: "Type",
                    placeholder: "Select a type",
                    PERSONAL: "Personal",
                    COMPANY: "Company"
                },
                phone: "Phone",
                email: "Email",
                iban: "IBAN",
                bic: "BIC",
                company_name: "Company name",
                vat_number: "VAT number",
                address: "Address",
                street: "Street",
                number: "Number",
                bus: "Bus",
                postal_code: "Postal code",
                city: "City",
                country: "Country",
                accountUsage: "Use account for:",
                isCommunication: "Communication",
                isInvoicing: "Invoicing",
                defaultForCommunication: "Standaard voor communicatie",
                defaultForInvoicing: "Standaard voor facturatie"
            }
        },
        masterlist: {
            title: "My starts",
            description: "Overview of your start times",
            results: "Results",
            history: "Transitions",
            empty: {
                title: "No start times",
                description: "All of your start times will appear here."
            },
            not_found: {
                title: "No search results",
                description: "We could not find any matches with your search."
            }
        },
        careers: {
            title: "My Careers",
            description: "Overview of your careers",
            empty: {
                title: "No Careers",
                description: "All your careers will appear here."
            },
            results: {
                title: "Results",
                empty: {
                    title: "No Results",
                    description: "No results found for this career."
                },
            },
            history: {
                title: "Transitions",
                empty: {
                    title: "No transitions",
                    description: "No transitions found for this career."
                },
            }
        },
        rankings: {
            championship: {
                title: "My Rankings",
                description: "Overview of your rankings",
                empty: {
                    title: "No Rankings",
                    description: "No rankings found for this year."
                }
            }
        },
        horses: {
            title: "My horses",
            description: "Overview of your horses",
            active: "Active",
            empty: {
                title: "No horses",
                description: "No horses found."
            }
        },
        videos: "Videos",
        rider: "Rider",
        horse: "Horse"
    },

    settings: {
        title: "Settings",
        account: {
            label: "Account data",
            change: "Change account"
        },
        preferences: {
            label: "Preferences",
            language: "Language",
            push_notifications: "Push notifications",
            email_notifications: "Email notifications"
        },
    },

    finishStatus: {
        FINISHED: "Finished",
        ELIMINATED: "Eliminated",
        RETIRED: "Retired",
        WITHDRAWN: "Withdrawn",
        DISQUALIFIED: "Disqualified",
        NOT_STARTED: "Not started"
    },

    homepage: {
        masterlist: {
            greeting: "Hello",
            title: "Your expected starts"
        }
    },

    empty: {
        arena: {
            title: "No live tests",
            description: "There are currently no live tests"
        },
        details: {
            title: "No details available"
        },
        cart: {
            title: "Your cart is empty",
            description: "You can checkout your registrations here"
        },
        startlist: {
            title: "Startlist is empty",
            description: "There are currently no startlist entries"
        },
        information: {
            title: "No info",
            description: "It's still a bit empty here"
        },
        live: {
            title: "No live events",
            description: "Please wait.."
        },
        events: {
            title: "It's a bit empty here",
            description: "Events will be back soon!"
        },
    },

    ranking: {
        total_points: "Total points",
        competitions: "Competitions"
    },

    syncing: {
        success: "Account sync success!",
        skip: 'Skip',
        modal: {
            title: "Sync account",
            description: "We were able to find some existing Eqify members with your email address",
            info: "Select yourself and/or any related persons",
            sync: "Sync",
            profile: {
                organisations: 'Organisation(s)',
                none: "None",
                horse_count: "Number of horses",
                select_type: "I am",
                unknown: "I do not know this person",
                relation_types: {
                    ME: "This is me",
                    MOTHER: "The mother",
                    FATHER: "The father",
                    OTHER_FAMILY: "another family member",
                    ASSOCIATE: "A manager"
                }
            }
        }
    },

    hippomundo: {
        certified: "Certified by Hippomundo.com",
        powered: "Powered by Hippomundo.com"
    },

    search: {
        title: "Search here..",
        info: {
            title: "Search",
            subtitle: "Find competitions, riders and horses"
        },
        recent: "Recent searches",
        loading: {
            title: "Searching..",
            subtitle: "This may take a few seconds"
        },
        no_results: {
            title: "No results found",
            subtitle: "We couldn't find any results with:"
        },
        competitions: "Competitions",
        combinations: "Combinations"
    }
}