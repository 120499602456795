<template>
  <ion-content>
    <ion-header>
      <ion-toolbar color="transparant">
        <title-with-small-description
            class="pt-3 pb-2"
            :title="$t('syncing.modal.title')"
            :description="$t('syncing.modal.description')"
        />
        <ion-buttons slot="end" v-if="!cannotSkip">
          <ion-button color="primary" @click="close">{{ $t('syncing.skip') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-item lines="none">
      <ion-label>
        <small>{{ $t('syncing.modal.info') }}</small>
      </ion-label>
    </ion-item>

    <ion-list>
      <ion-item @click="itemClicked(person)"
                mode="ios"
                lines="full"
                class="pl-1 pr-1 mb-2 personItemClass"
                v-for="(person, idx) in data.persons"
                :key="idx"
      >
        <ion-avatar slot="start">
          <img alt="p-a" v-if="person.avatar" :src="person.avatar">
          <img alt="p-a" v-else src="/assets/icon/favicon.png">
        </ion-avatar>
        <ion-label>
          <h6 class="text-muted name_with_sub_item">
            <span>{{ person.firstname }} {{ person.lastname }}</span>
            <small class="text_italic"> {{ person.birthYear }}</small>
          </h6>
        </ion-label>
        <ion-label slot="end">
          <template v-if="isSelected(person.ref)">
            <ion-text color="success">
              {{ $t(`syncing.modal.profile.relation_types.${selectedPersonRole(person.ref)}`) }}
              <ion-icon :icon="checkmarkOutline"/>
            </ion-text>
          </template>
        </ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer class="background-dark">
    <ion-button @click="submit()" :disabled="!hasMe()" class="m-2 mb-4 mt-3" color="tertiary" expand="block">
      {{ $t('syncing.modal.sync') }}
    </ion-button>
  </ion-footer>
</template>

<script lang="ts" setup>
import {
  IonItem,
  IonList,
  IonContent,
  IonLabel,
  IonIcon,
  IonText,
  IonFooter,
  IonButton,
  IonAvatar,
  modalController,
  IonToolbar,
  IonButtons,
  IonHeader
} from '@ionic/vue';
import {defineProps, ref} from "vue";
import {checkmarkOutline} from 'ionicons/icons';
import {
  authenticationService,
  LoggedInUserCheck,
  LoggedInUserCheckConfirmation, LoggedInUserChild,
  PersonMetaData
} from "@/authentication/AuthenticationService";
import TitleWithSmallDescription from "@/component/TitleWithSmallDescription.vue";
import LoggedInUserCheckProfileModal from "@/component/LoggedInUserCheckProfileModal.vue";

let selected = ref<Array<SelectedPerson>>([])

const props = defineProps<{
  data: LoggedInUserCheck,
  email: string,
  cannotSkip: boolean
}>()

async function itemClicked(person: PersonMetaData) {
  const modal = await modalController.create({
    mode: 'ios',
    breakpoints: [0, 0.75, 1],
    initialBreakpoint: 0.75,
    component: LoggedInUserCheckProfileModal,
    componentProps: {
      data: person,
      showMeOption: hasMe(),
      showOnlyMeOption: props.data.persons.length == 1
    }
  })

  await modal.present()

  const modalResponse = await modal.onWillDismiss()

  if (modalResponse.role == 'select-none') {
    const existing = selected.value.find(it => it.ref == modalResponse.data.ref)
    if (existing) {
      const idx = selected.value.findIndex(it => it.ref == existing.ref)
      selected.value.splice(idx, 1)
    }
  }

  if (modalResponse.role == 'select') {
    const existing = selected.value.find(it => it.ref == modalResponse.data.ref)
    if (existing) {
      existing.role = modalResponse.data.role
    } else {
      selected.value.push({
        ref: modalResponse.data.ref,
        role: modalResponse.data.role
      })
    }
  }
}

function isSelected(ref: string): boolean {
  return selected.value.find(it => it.ref == ref) != undefined
}

function selectedPersonRole(ref: string): string {
  return selected.value.find(it => it.ref == ref)!.role
}

function hasMe(): boolean {
  return selected.value.find(it => it.role == 'ME') != undefined
}

function me(): SelectedPerson {
  return selected.value.find(it => it.role == 'ME')!
}

function close() {
  modalController.dismiss(null, 'cancel')
}

async function submit() {
  await authenticationService.userCheckConfirmation(
      props.email,
      {
        personRef: me().ref,
        relations: selected.value.filter(it => it.role != 'ME').map(it => {
          return {
            personRef: it.ref,
            relationType: it.role
          } as LoggedInUserChild
        })
      } as LoggedInUserCheckConfirmation
  )
  await modalController.dismiss(null, 'submit')
}

export interface SelectedPerson {
  ref: string,
  role: string
}

</script>

<style lang="scss" scoped>
</style>